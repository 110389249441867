import { indonesia_payment } from "../../js/path";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      getCouponType: [
        { value: "fixed", text: "Fixed Amount" },
        { value: "percentage", text: "Percentage" },
      ],
      pageNo: null,
      status: false,
      activeTab: "all",
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      items: [
        {
          text: "Back",
          href: "/coupon",
        },
        {
          text: "Data",
        },
      ],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "code",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "value",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "uses",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "valid_from",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "valid_till",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "discount_amount",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
        },

        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        code: "",
        type: "",
        value: "",
        uses: "",
        valid_from: "",
        valid_till: "",
        discount_amount: "",
        status: 0,
        multi_coupons: [],
        product: null,
      },
      getCoupons: [],
      getProduct: [],
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    productChange() {
      this.form.multi_coupons = [];
    },

    disabledPastDates() {
      const currentDate = new Date();
      return currentDate.toISOString().slice(0, 10);
    },

    async fetchEvent() {
      const url = indonesia_payment?.getCouponItem;
      const data = await this.getRequest(
        `${apiEndpoint}${url}/${this.form.product?.id}`
      );
      if (data.status) {
        this.getCoupons = data.response.data;
      }
    },

    async fetchProducts() {
      const url = indonesia_payment?.getCouponProducts;
      const res = await this.getRequest(`${apiEndpoint}${url}`);
      if (res.status) {
        this.getProduct = res.response.data;
      }
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "in_active") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      } else if (txt == "trash") {
        this.activeTab = txt;
      }

      let request = `${indonesia_payment?.getCoupon}?page=${
        this.currentPage ?? 1
      }&filter=${txt ?? ""}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async fetchCoupon(id) {
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.getCoupon}/${id}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            const responseData = res.response;
            if (responseData.id != "" && responseData.id != null) {
              this.form.id = responseData.id;
            }
            if (responseData.code != "" && responseData.code != null) {
              this.form.code = responseData.code;
            }
            if (responseData.type != "" && responseData.type != null) {
              this.form.type = responseData.type;
            }
            if (responseData.value != "" && responseData.value != null) {
              this.form.value = responseData.value;
            }
            if (responseData.uses != "" && responseData.uses != null) {
              this.form.uses = responseData.uses;
            }
            if (
              responseData.valid_from != "" &&
              responseData.valid_from != null
            ) {
              this.form.valid_from = responseData.valid_from;
            }
            if (
              responseData.valid_till != "" &&
              responseData.valid_till != null
            ) {
              this.form.valid_till = responseData.valid_till;
            }
            if (
              responseData.discount_amount != "" &&
              responseData.discount_amount != null
            ) {
              this.form.discount_amount = responseData.discount_amount;
            }
            if (responseData.status) {
              this.form.status = true;
            }
            if (
              responseData.product_coupons != "" &&
              responseData.product_coupons != null
            ) {
              this.form.product = responseData.product_coupons;
            }
            if (
              responseData.coupon_items != "" &&
              responseData.coupon_items != null
            ) {
              this.form.multi_coupons = responseData.coupon_items.map(
                (item) => {
                  return {
                    id: item.id,
                    name: item.name,
                  };
                }
              );
            }
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      let index = this.tableData.data.findIndex((e) => e.id === id);
      const request = indonesia_payment.updateCouponStatus + "/" + id;
      axios
        .post(`${apiEndpoint}${request}`, {
          status: !this.tableData.data[index].status,
        })
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData.data[index].status =
              !this.tableData.data[index].status;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: "Status updated successfully",
            });
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        const value = parseFloat(this.form.value);
        const discountAmount = parseFloat(this.form.discount_amount);

        if (discountAmount > value) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Discount amount should be less than value.",
          });
          return false;
        }

        this.$store.commit("loader/updateStatus", true);
        if (this.$route.name == "edit-coupon") {
          let url = `${apiEndpoint}${indonesia_payment?.getCoupon}/${this.form.id}`;
          let editdata = {};
          for (var item in this.form) {
            if (
              item != "multi_coupons" &&
              item != "product" &&
              item != "discount_amount"
            ) {
              editdata[item] = this.form[item];
            }
          }
          let reference_arr = [];
          for (var ref_id in this.form.multi_coupons) {
            reference_arr.push(this.form.multi_coupons[ref_id].id);
          }
          editdata.reference_id = reference_arr;

          editdata.product_id = this.form.product?.id;
          editdata.slug = this.form.product?.slug;
          editdata.discount_amount = parseInt(this.form.discount_amount);

          const request = await axios.put(url, editdata);
          const response = request?.data;
          if (response?.status) {
            console.log(response?.message, "data.message");
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/coupon");
          }
        } else {
          let url = `${apiEndpoint}${indonesia_payment?.getCoupon}`;
          let dataAppend = new FormData();
          if (this.form.status) {
            dataAppend.append("status", 1);
          } else {
            dataAppend.append("status", 0);
          }
          for (var key in this.form) {
            if (key != "status" && key != "multi_coupons" && key != "product") {
              dataAppend.append(key, this.form[key]);
            }
          }
          for (var k in this.form.multi_coupons) {
            dataAppend.append("reference_id[]", this.form.multi_coupons[k].id);
          }
          if (this.form.product) {
            dataAppend.append("product_id", this.form.product?.id);
            dataAppend.append("slug", this.form.product?.slug);
          }

          const request = await axios.post(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            console.log(response?.message, "data.message");
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/coupon");
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteCoupon(id) {
      console.log(id);
      this.$store.commit("loader/updateStatus", true);
      let index = this.tableData.data.findIndex((e) => e.id === id);
      const request = indonesia_payment.getCoupon + "/" + id;
      axios
        .post(`${apiEndpoint}${request}`, {
          _method: "DELETE",
        })
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData.data.splice(index, 1);
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
      this.$store.commit("loader/updateStatus", false);
    },
    changeValidFrom() {
      this.form.valid_till = "";
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    status(v) {
      if (v == 1) {
        this.fetchEvent();
        this.form.multi_coupons = this.getCoupons;
      } else {
        this.form.multi_coupons = [];
      }
    },
    "form.product": {
      handler: function () {
        this.getCoupons = [];
        this.fetchEvent();
      },
    },
  },
  created() {
    if (this.$route.name == "add-coupon" || this.$route.name == "edit-coupon") {
      this.fetchProducts();
      if (this.$route.name == "edit-coupon") {
        this.fetchCoupon(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
  mounted() {},
};
